<template>
  <div>
    <div class="container-fluid">
      <PageTitle />

      <b-tabs v-if="isList">
        <b-tab title="List of Blogs">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <h5 class="card-title">{{ pageTitle }} List</h5>
                </div>
                <div class="col-3">
                  <VSelect
                    v-model="filterStatus"
                    placeholder="-- All Status --"
                    :options="mrStatus"
                    :reduce="(v) => v.status"
                    label="status_description"
                  >
                  </VSelect>
                </div>
                <div class="col-3">
                  <VSelect
                    v-model="filterFeatured"
                    placeholder="-- All Featured Status--"
                    :options="mrFeatured"
                    :reduce="(v) => v.status"
                    label="status_description"
                  >
                  </VSelect>
                </div>
                <div class="col-md-1">
                  <button class="btn btn-info" type="button" @click="doReset">
                    Reset
                  </button>
                </div>
              </div>
              <hr>
              <div class="row mt-4">
                <b-col md="4" class="mb-4" v-for="(v, k) in data.data" :key="k">
                  <article class="card_list">
                    <div class="wrap_thumb_list">
                      <bo-card-img
                        :src="uploader(v.fallback_img)"
                        :title="`${v.category_name} - ${v.title}`"
                      >
                        <template #status>
                          <LabelStatus type="spda" :status="v[statusKey]" />
                          <LabelStatus class="mx-1" type="featured" :status="v.is_featured" />
                        </template>
                        <template #buttons>
                          <b-button
                            v-if="moduleRole('Edit')"
                            variant="secondary"
                            size="sm"
                            class="mx-1"
                            pill
                            @click.prevent="
                              $router
                                .push({
                                  name: 'Blog',
                                  params: { pageSlug: v.id },
                                })
                                .catch(() => {})
                            "
                            v-b-tooltip.hover="'Edit'"
                          >
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                          <b-button
                            variant="danger"
                            size="sm"
                            pill
                            @click="doDelete(k, v)"
                            v-b-tooltip.hover="'Delete'"
                          >
                            <i class="fas fa-trash-alt" />
                          </b-button>
                        </template>
                      </bo-card-img>
                    </div>
                  </article>
                </b-col>
                <b-col v-if="notFound"><span colspan="99" class="text-center"><h4>{{notFound}}</h4></span></b-col>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <div
                    class="pagination-flat float-right"
                    v-if="data.data !== false && data.data.length"
                  >
                    <Pagination
                      :data="data"
                      :limit="2"
                      @pagination-change-page="doPaginate"
                    >
                      <span slot="prev-nav">Previous</span>
                      <span slot="next-nav">Next</span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        
        <template v-for="(vlang,klang) in lang">
          <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
            <bo-card title="Hero Image" footer-tag="footer">
              <b-row class="gutter-2">
                <b-col md="3">
                  <bo-card-img
                    :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                    :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                    :isTitleHtml="true"
                  >
                    <template #buttons>
                      <b-button
                        v-if="moduleRole('Edit')"
                        variant="secondary"
                        size="sm"
                        pill
                        @click="editHeroImage(content['hero_' + vlang.value])"
                        v-b-tooltip.hover="'Edit'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
            </bo-card>

          </b-tab>
        </template>
        <template v-for="(vlangseo,klangseo) in lang">
          <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver 
                      v-slot="{ handleSubmit }" 
                      :ref="`VFormSeo${vlangseo.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'seo-setting',
                              content['seo_' + vlangseo.value],
                              `VFormSeo${vlangseo.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label :for="`seoTitle${vlangseo.value}`">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-input
                                      :id="`seoTitle${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`seoTitle${vlangseo.value}`"
                                      name="Title"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-textarea
                                      :id="`seoDescription${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      rows="5"
                                    ></b-form-textarea>
                                    <VValidate
                                      :vid="`seoDescription${vlangseo.value}`"
                                      name="Description"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      rules="required|min:10|max:500"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="moduleRole('Edit')">
                          <div class="col-12">
                            <hr />
                            <div class="text-right">
                              <template v-if="editFormId == `seo${vlangseo.value}`">
                                <button
                                  type="button"
                                  @click="editFormId = ''"
                                  class="btn btn-rounded btn-light mr-2"
                                >
                                  Cancel
                                </button>
                                <button type="submit" class="btn btn-rounded btn-primary">
                                  Save
                                </button>
                              </template>
                              <template v-else>
                                <button
                                  type="button"
                                  @click="editFormId = `seo${vlangseo.value}`"
                                  class="btn btn-rounded btn-primary"
                                >
                                  Ubah
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </template>

        <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
          <b-modal
            id="heroImageModal"
            size="md"
            title="Edit Hero Image"
            @ok.prevent="
              handleSubmit(
                updateContent(
                  'hero-image',
                  inputHeroImage,
                  'VFormHeroImage',
                  'heroImageModal'
                )
              )
            "
          >
            <template #default>
              <div class="infoHTML"></div>
              <b-form 
                @submit.prevent="
                    handleSubmit(
                      updateContent(
                        'hero-image',
                        inputHeroImage,
                        'VFormHeroImage'
                      )
                    )
                  "
              >
                <b-row>
                  <b-col md="12">
                    <BoField 
                      label="Sub Title"
                      placeholder="e.g. MEDIA CENTER"
                      v-model="((inputHeroImage || {}).value || {}).sub_title"
                      :rules="mrValidation.sub_title"
                    />
                  </b-col>
                  <b-col md="12">
                    <BoField 
                      label="Title"
                      placeholder="e.g. Jakarta Running Festival 2024"
                      v-model="((inputHeroImage || {}).value || {}).title"
                      :rules="mrValidation.hero_image.title"
                    />
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>
                        Image <span class="text-danger mr5">*</span>
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="((inputHeroImage || {}).value || {}).fallback_img"
                        :param="{ thumbnail: true }"
                        :squarePreview="true"
                        type="hero_image"
                        label="Image"
                        @data="v => ((inputHeroImage || {}).value || {}).img = v"
                      />
                      <VValidate
                        name="Image"
                        v-model="((inputHeroImage || {}).value || {}).img"
                        :rules="mrValidation.hero_image.img"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>
                        Small Image <span class="text-danger mr5">*</span>
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                        :param="{ thumbnail: true }"
                        :squarePreview="true"
                        type="hero_small"
                        label="Image"
                        @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                      />
                      <VValidate
                        name="Image"
                        v-model="((inputHeroImage || {}).value || {}).img_small"
                        :rules="mrValidation.hero_image.img_small"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <BoField 
                      label="Alt Image"
                      placeholder="e.g. Hero Image"
                      v-model="((inputHeroImage || {}).value || {}).alt_img"
                      :rules="mrValidation.hero_image.alt_img"
                    />
                  </b-col>
                  <hr />
                </b-row>
              </b-form>
            </template>

            <template #modal-footer="{ ok, cancel }">
              <b-button variant="outline-secondary" @click="cancel()">
                Cancel
              </b-button>
              <b-button variant="info" @click="ok()">
                Submit
              </b-button>
            </template>
          </b-modal>
        </ValidationObserver>
      </b-tabs>

      <div v-else class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">
            {{ isAdd ? "Add" : "Edit" }} {{ pageTitle }}
          </h5>

          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
            <form @submit.prevent="handleSubmit(doSubmitCRUD)">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Category
                          <span class="text-danger mr5">*</span>
                        </label>
                        <select2
                          :options="mrCategory"
                          :object="['id', 'name']"
                          v-model="row.category"
                        >
                          <option value="">-- Choose Category --</option>
                        </select2>
                        <VValidate
                          name="Category"
                          v-model="row.category"
                          :rules="mrValidation.mbc_id"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Author
                          <span class="text-danger mr5">*</span>
                        </label>
                        <select2
                          :options="mrAuthor"
                          :object="['id', 'name']"
                          v-model="row.author"
                        >
                          <option value="">-- Choose Author --</option>
                        </select2>
                        <VValidate
                          name="Author"
                          v-model="row.author"
                          :rules="mrValidation.ma_id"
                        />
                      </div>
                    </div>
                    <b-col md="6">
                      <b-form-group>
                        <label>
                          Publish Date 
                          <span class="text-danger mr5">*</span>
                        </label>
                        <br>
                        <v-date-picker locale="en" :popover="{ visibility: 'click' }" v-model="row.publish_date" mode="date" :min-date="new Date()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              class="px-2 py-1 border form-control w-100 rounded focus:outline-none focus:border-blue-300"
                              :value="formatDateShort(inputValue)"
                              v-on="inputEvents"
                              :placeholder="
                              row.publish_date && 
                              $moment(row.publish_date).diff($moment().startOf('day'), 'days') < 0 
                              ? formatDateShort(row.publish_date) 
                              : 'Choose a date'"
                            />
                          </template>
                        </v-date-picker>
                        <VValidate 
                          name="Published Date"
                          v-model="row.publish_date"
                          :rules="mrValidation.publish_date"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <label>
                          Title 
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input
                          v-model="row.title"
                          type="text"
                          class="form-control"
                          placeholder="e.g. 42k challenge for experienced and determined long-distance runner"
                          @input="
                            fillSeoTitle($event)
                          "
                        />
                        <VValidate 
                          name="Title"
                          v-model="row.title"
                          :rules="mrValidation.title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <div class="form-group">
                        <label>
                          Excerpt Description
                          <span class="text-danger mr5">*</span>
                        </label>
                        <b-form-textarea
                          v-model="row.excerpt"
                          :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                          rows="4"
                          @input="
                            fillSeoDescription
                          "
                        ></b-form-textarea>
                        <VValidate
                          name="Excerpt Description"
                          v-model="row.excerpt"
                          :rules="mrValidation.excerpt"
                        />
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="form-group">
                        <label>
                          Content of Blog
                          <span class="text-danger mr5">*</span>
                        </label>
                        <ckeditor
                          :editor="editor"
                          v-model="row.description"
                          :config="editorConfig"
                        ></ckeditor>
                        <VValidate
                          vid="description"
                          name="Description"
                          v-model="row.description"
                          :rules="mrValidation.description"
                        />
                      </div>
                    </b-col>
                    <b-col md="12" class="border-top pt-4">
                      <BoField
                        label="Meta Title"
                        placeholder="Pre-Release JRF 2024"
                        v-model="row.meta_title"
                        :rules="mrValidation.meta_title"
                      />
                    </b-col>
                    <b-col md="12" class="border-bottom pb-2 mb-4">
                      <div class="form-group">
                        <label>
                          Meta Description
                          <span class="text-danger mr5">*</span>
                        </label>
                        <b-form-textarea
                          v-model="row.meta_description"
                          :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                          rows="4"
                        ></b-form-textarea>
                        <VValidate
                          name="Meta Description"
                          v-model="row.meta_description"
                          :rules="mrValidation.meta_description"
                        />
                      </div>
                    </b-col>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Status
                          <span class="text-danger mr5">*</span>
                        </label>
                        <div>
                          <InputRadio
                            v-model="row[statusKey]"
                            name="status"
                            option="D"
                            label="Draft"
                          />
                          <InputRadio
                            v-model="row[statusKey]"
                            name="status"
                            option="P"
                            label="Publish"
                          />
                          <InputRadio
                            v-model="row[statusKey]"
                            name="status"
                            option="A"
                            label="Archive"
                          />
                        </div>
                        <VValidate
                          name="Status"
                          v-model="row[statusKey]"
                          :rules="mrValidation[statusKey]"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label">
                          Is Featured?
                          <span class="text-danger mr5">*</span>
                        </label>
                        <div>
                          <InputRadio
                            v-model="row.is_featured"
                            name="is_featured"
                            option="Y"
                            label="Yes"
                          />
                          <InputRadio
                            v-model="row.is_featured"
                            name="is_featured"
                            option="N"
                            label="No"
                          />
                        </div>
                        <VValidate
                          name="Status"
                          v-model="row.is_featured"
                          :rules="mrValidation.is_featured"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <b-col md="12">
                      <b-form-group>
                        <label>
                          Image <span class="text-danger mr5">*</span>
                        </label>
                        <Uploader
                          :readonly="!moduleRole('Edit')"
                          v-model="row.fallback_img"
                          :param="{ thumbnail: true }"
                          :squarePreview="true"
                          type="preview_blog"
                          label="Image"
                          @data="(v) => (row.img = v)"
                        />
                        <VValidate
                          name="Image"
                          v-model="row.img"
                          :rules="mrValidation.img"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <BoField
                        label="Alt Image"
                        placeholder="Alt Image"
                        v-model="row.alt_img"
                        :rules="mrValidation.alt_img"
                      />
                    </b-col>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 text-right">
                  <router-link
                    :to="{ name: $route.name }"
                    class="btn btn-rounded btn-light mr-2"
                    >Cancel</router-link
                  >
                  <button type="submit" class="btn btn-rounded btn-info">
                    {{ isAdd ? "Submit" : "Update" }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from "@/components/BoCardImg.vue";
import assign from "lodash/assign";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import debounce from "lodash/debounce";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    BoCard,
    BoCardImg,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      idKey: "id",
      statusKey: "is_active",
      mrCategory: [],
      mrAuthor: [],
      mrFeatured: [],
      editor: ClassicEditor,
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link", 'numberedList', 'bulletedList', 'imageUpload', "insertTable", 'sourceEditing'],
        },
      },
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      editFormId: "",
      content: [],
      inputHeroImage: {}
    };
  },
  computed: {
    filterStatus: {
      get() {
        return this.filter.status;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          status: newValue,
        };
        this.doFilter();
      },
    },
    filterFeatured: {
      get() {
        return this.filter.featured;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          featured: newValue,
        };
        this.doFilter();
      },
    },
  },
  methods: {
    fillSeoTitle: debounce(function (e) {
      let refValue = `${e.target.value.substr(0, 160)}`;
      this.row.meta_title = refValue;
      this.row.alt_img = refValue;
    }, 500),
    fillSeoDescription: debounce(function (value) {
      /* eslint-disable no-control-regex  */
      /* eslint-disable no-useless-escape  */
      let el = document.createElement("div");
      el.innerHTML = value;

      if (el.textContent.length >= 160) {
        this.row.meta_description = el.textContent.match(
          new RegExp("^.{0,160}")
        )[0];
      } else {
        this.row.meta_description = el.textContent;
      }
    }, 500),
    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route: {
      handler() {
        this.apiGet();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>